










































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThingsEventEntityModel, ThingsEventQueryModel } from '@common-src/entity-model/things-event-entity';
import ThingsEventService from '@common-src/service/things-event';
import ThingsSyncDialog from '../../things-template/things-sync-dialog.vue';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';

@Component({
    components: {
        'things-sync-dialog': ThingsSyncDialog
    }
})
export default class ThingsEventListComponent extends TableDialogFormComponent<ThingsEventEntityModel, ThingsEventQueryModel> {
    ThingsEventEntityModel = ThingsEventEntityModel;
    created() {
        console.log('event', this);
        this.initTable({ service: ThingsEventService, queryModel: new ThingsEventQueryModel(), tableColumns: ThingsEventEntityModel.getTableColumns() });
        this.getList();
    }

    // upSysncClick() {
    //     (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.UPLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.EVENT);
    // }

    // downSysncClick() {
    //     (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.EVENT);
    // }
}

